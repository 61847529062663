<template>
	<PopupLayer class="z-index-pin">
		<template
				v-slot:body>
			<div
				class="flex-column radius-20 text-center	radius-20 overflow-hidden" id="popup_view"
				style="min-width: 320px; max-width: 480px; height: 700px; margin: 0 auto;"
			>

				<div class="flex-column color-white">
					<div class="flex-1 flex-column justify-center pa-30">
						<div class="justify-end">
							<button class="arrow" @click="$emit('close')">
								<i class="icon-close color-white "></i>
							</button>
						</div>
						<div>
							<img :src="require('@/assets/image/web/ico_logo_small.svg')"/>
						</div>
						<div class="text_guide mt-30">
							<h4 style="color: white">{{ pin_text }}</h4>
						</div>
						<div class="flex-row justify-space-around mt-30 pa-30">
							<div
									v-for="(item, index) in pinForType"
									:key="'pin_' + index"
									class="word"
									:class="{ on: item }"
							></div>
						</div>
					</div>

					<div id="alert-wrap" class="alert-wrap" style="position: absolute" v-html="message">
						<!--			<div class="alert-content bg-red"><i class="icon-alert-fail"></i> <span>"+'테스트'+"</span></div>-->
					</div>

					<div class="password_keyboard flex-column justify-space-around keyboard-radius mt-40">
						<table class="keyboard ">
							<tbody>
							<tr
									v-for="num in 3"
									:key="'num_' + num"
							>
								<td
										v-for="num_s in 3"
										:key="'num_' + num + '_' + num_s"
								>
									<button type="button" class="key" @click="setPin(num_s + (3 * (num - 1)))">
										<span>{{ num_s + (3 * (num - 1)) }}</span>
									</button>
								</td>
							</tr>
							<tr>
								<td>
									<button
											type="button" class="key"
											@click="$emit('close')"
									>
										취소
									</button>
								</td>
								<td>
									<button type="button" class="key" @click="setPin(0)">
										<span>0</span>
									</button>
								</td>
								<td>
									<button type="button" class="key" @click="back">
										<span class="ico_del"><em class="hide">지우기</em></span>
									</button>
								</td>
							</tr>
							</tbody>
						</table>
<!--						<div
								class="find_link "
						>
							<button class="color-white" @click="lostPin">
								혹시 PIN번호를 잊으셨나요? <i class="icon-arrow-right"></i>
							</button>
						</div>-->
					</div>
				</div>
			</div>
		</template>

	</PopupLayer>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";

export default {
	name: "Pin",
	components: {PopupLayer},
	props : ['user', 'pin_type', 'next_pin_type'],
	data() {
		return {
			step: 0
			, step_confirm: 0
			, max: 6
			, pin: [false, false, false, false, false, false]
			, pin_confirm: [false, false, false, false, false, false]
			, is_confirm: false
			, message : ''

			, schedulingPID : '', //현재 진행중인 메시지 스케줄링 프로세스 PID
		}
	},

	computed : {
		pinForType: function () {

			if (this.is_confirm) {
				return this.pin_confirm
			} else {
				return this.pin
			}
		}

	, pin_text: function () {
		let t = ''

		if (this.pin_type == 'set') {
			if (this.is_confirm) {
				t = 'PIN번호를 한번 더 입력하세요'
			} else {
				t = '보안을 위해 PIN번호를 설정하세요'
			}
		} else if (this.pin_type == 'update') {
			if (this.is_confirm) {
				t = 'PIN번호를 한번 더 입력하세요'
			} else {
				t = '신규 PIN번호를 입력하세요'
			}
		} else if (this.pin_type == 'lost') {
			if (this.is_confirm) {
				t = 'PIN번호를 한번 더 입력하세요'
			} else {
				t = '신규 PIN번호를 입력하세요'
			}
		} else if (this.pin_type == 'join') {
			if (this.is_confirm) {
				t = 'PIN번호를 한번 더 입력하세요'
			} else {
				t = '신규 PIN번호를 입력하세요'
			}
		} else {
			t = '보안 PIN번호를 입력하세요.'
		}

		return t
	}
	},

	methods : {

		setPin: function (number) {
			if (this.is_confirm) {
				if (!this.pin_confirm[this.step_confirm]) {
					this.$set(this.pin_confirm, this.step_confirm, number + '')
					this.step_confirm++
				}
			} else {
				if (!this.pin[this.step]) {
					this.$set(this.pin, this.step, number + '')
					this.step++
				}
			}
		}

		, back: function () {
			if (this.is_confirm) {
				this.step_confirm--
				this.$set(this.pin_confirm, this.step_confirm, false)
			} else {
				this.step--
				this.$set(this.pin, this.step, false)
			}
		}

		, lostPin: function () {
			let random = this.getRandomArbitrary(1,100)
			this.$router.replace({path: '/auth/pin_sms/' + random})
			//this.$emit('cancel')
			//this.$router.replace({name: 'pin_sms'})
		}

		, getRandomArbitrary(min, max) {
			return Math.random() * (max - min) + min;
		}



		// MEM-17-001 핀 번호 변경 핀 인증
		, postPinCheck: async function () {
			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_pin_check
					, data: {
						member_number: this.user.member_number
						, pinnumber: this.pin.toString().replaceAll(',', '')
					}
					, type: true
				})
				if (result.success) {
					if(result.data.session_token){
						await this.$encodeStorage.setSessionToken(result.data.session_token)
					}
					this.showMessage('새로운 PIN 번호를 입력해주세요.')
					this.$emit('changePinType', 'update')
				} else {
					this.showMessage(result.message, 'error')
					//throw result.message
				}
			} catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.clear()
			}
		}

		, postPinUpdate: async function () {
			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_pin_update
					, data: {
						member_number: this.user.member_number
						, chg_pinnumber: this.pin.toString().replaceAll(',', '')
						, chg_pinnumber_confirmation: this.pin_confirm.toString().replaceAll(',', '')
					}
					, type: true
				})
				if (result.success) {

					if(result.data.session_token){
						await this.$encodeStorage.setSessionToken(result.data.session_token)
					}
					this.$emit('click', this.pin.toString().replaceAll(',', ''))
				} else {
					throw result.message
				}
			} catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.reset()
			}
		}

		, clear: function () {
			if (this.is_confirm) {
				this.pin_confirm = [false, false, false, false, false, false]
				this.step_confirm = 0
			} else {
				this.pin = [false, false, false, false, false, false]
				this.step = 0
			}
		}

		, reset: function () {
			this.is_confirm = false
			this.step = 0
			this.step_confirm = 0
			this.pin = [false, false, false, false, false, false]
			this.pin_confirm = [false, false, false, false, false, false]
			this.$emit('close')
		}

		, showMessage(message = '', type = '') {
			this.clearSchedulingPID()
			if (type === 'error') {
				this.message = "<div class=\"alert-content bg-red\"><i class=\"icon-alert-fail\"></i> <span>" + message + "</span></div>"
			} else {
				this.message = "<div class=\"alert-content bg-green\"><i class=\"icon-alert-fail\"></i> <span>" + message + "</span></div>"
			}

			this.schedulingPID = setTimeout(() => {
				this.message = ''
			}, 2000)
		}

		, clearSchedulingPID() {
			clearTimeout(this.schedulingPID)
		}
	},

	watch: {	
		step: {
			handler: function (call) {
				if (call > this.max) {
					this.step = 0
					this.pin = [false, false, false, false, false, false]
					this.is_confirm = true
				}
			}
		}
		, step_confirm: {
			handler: function (call) {
				if (call > this.max) {
					this.step_confirm = 0
					this.pin_confirm = [false, false, false, false, false, false]
				}
			}
		}


		, pin: {
			deep: true
			, handler: function (call) {
				if (this.pin_type == 'set' || this.pin_type == 'join' || this.pin_type == 'update' || this.pin_type == 'lost' || this.pin_type == 'pin_modify_before_login') {

					let is_set = false
					call.forEach(function (val) {
						if (val === false) {
							is_set = false
							return false
						} else {
							is_set = true
						}
					})


					if (is_set) {
						this.is_confirm = true
					}
				} else if (this.pin_type == 'check' || this.pin_type == 'otp_pin_check') {
					let is_set = false
					call.forEach(function (val) {
						// this.$log.console(val + ' : ' + typeof val)
						if (val === false) {
							is_set = false
							return false
						} else {
							is_set = true
						}
					})

					if (is_set) {
						if (this.pin_type == 'otp_pin_check') {
							this.postOtpPinCheck()
						} else {
							this.postPinCheck()
						}
					}
				}
			}
		}
		, pin_confirm: {
			deep: true
			, handler: function (call) {
				if (this.is_confirm) {

					let is_set = false
					call.forEach(function (val) {
						if (!val) {
							is_set = false
							return false
						} else {
							is_set = true
						}
					})

					if (is_set) {
						if (this.pin.toString() == this.pin_confirm.toString()) {
							if (this.pin_type === 'update') {
								this.postPinUpdate()
							} else if (this.pin_type === 'pin_modify_before_login') {
								this.postPinBeforLogin()
							} else if (this.pin_type === 'lost') {
								//this.$emit('click', this.pin.toString().replaceAll(',', ''), this.pin_confirm.toString().replaceAll(',', ''))
								this.$emit('lostPinConfirm', this.pin.toString().replaceAll(',', ''))
							} else {
								this.postPin()
							}
						} else {
							this.$bus.$emit('notify', {type: 'error', message: 'PIN 번호가 맞지 않습니다. 다시 시도해주세요.'})
							this.clear()
						}
					}
				}
			}
		}

	}
}
</script>

<style scoped>
.password_keyboard {
	background-color: #181A39;
	height: 350px;
}

.word {
	width: 24px;
	height: 24px;
	border-radius: 50px;
	background-color: #24264E;
}

.word.on {
	background-color: #3E47B7;
}

.keyboard td {
	width: 33.33%;
	font-size: 24px;
	height: 50px;
	padding: 10px;
}

.text_guide {
	font-size: 20px;
}

.keyboard-radius {
	border-radius: 40px 40px 0 0;
}


/*alert*/
.alert-wrap {
	position: absolute;
	z-index: 500;
	top: 47%;
	left: 50%;
	width: 100%;
	max-width: 400px;
	padding: 0 20px;
	transform: translate(-50%, -50%);
	text-align: center;
}

.alert-content {
	padding: 12px;
	font-size: 14px;
	border-radius: 5px;
	background: rgba(0, 0, 0, 0.7);
}

.alert-content + .alert-content {
	margin-top: 10px;
}

.alert-content i {
	position: relative;
	top: 1px;
	margin-right: 5px;
	color: #fff;
}

.alert-content span {
	color: #fff;
	line-height: 20px;
}

.bg-red {
	background: rgba(255, 18, 18, 0.7) !important;
}

#popup_view {
	background-color: #0b0c1a;
}

.keyboard .key span.ico_del { display: block; width: 2.7rem; height: 2rem; background: url(../../../assets/image/ico_del.png)no-repeat center center / 2.7rem 2rem;}
</style>